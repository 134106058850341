import React from "react"
import Layout from "../components/layout_menu"
import content from "../../content/menuitems.yaml"
export default () => (
  <>
  <Layout>
  <section id="top"
    style= {{
      padding: '60px 0 1px',
      background: '#F0F0F0',
      textalign: 'center'
    }}>
      <div className="row add-bottom">
        <div className="six columns add-bottom">
          <h2>Dine In Menu</h2>
        </div>  
      </div>
  </section>
  
  <section id="appetizeritems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0',
      textalign: 'center'
    }}
  >
    <div className="row add-bottom">
      <div className="six columns add-bottom">
        <h5>Appetizers</h5>
        <hr/>
            { content.appetizeritems.map((appetizeritem, index) =>
            <div className="row add-bottom">
              <div>  
                        <li>{ appetizeritem.name } - { appetizeritem.price } <p></p>{ appetizeritem.chinese } </li>
               </div>
            </div>
             )
          }
      </div>    
    </div>
    </section>
    
    <section id="soupitems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Soup</h5>
      <hr />
            { content.soupitems.map((soupitem, index) =>
            <div className="row add-bottom">
              <div>

                        <li>{ soupitem.name } - { soupitem.price } <p></p>
                        { soupitem.chinese } </li>
               </div>
            </div>
             )
          }
    </div>
    </div>
    </section>
    
    <section id="beefandporkitems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Beef and Pork</h5>
      <hr />
            { content.beefandporkitems.map((beefandporkitem, index) =>
            <div className="row add-bottom">
              <div>
                        <li>{ beefandporkitem.name } - { beefandporkitem.price } <p></p>{ beefandporkitem.chinese } </li>

               </div>
            </div>
             )
          }
    </div>
    </div>
    </section>
    
    <section id="seafooditems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Seafood</h5>
      <hr />
            { content.seafooditems.map((seafooditem, index) =>
            <div className="row add-bottom">
              <div>
                        <li>{ seafooditem.name } - { seafooditem.price } <p></p>{ seafooditem.chinese } </li>
               </div>
            </div>
             )
          }
    </div>
    </div>
    </section>
    
    <section id="chickenitems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Chicken</h5>
      <hr />
            { content.chickenitems.map((chickenitem, index) =>
            <div className="row add-bottom">
              <div>

                        <li>{ chickenitem.name } - { chickenitem.price } <p></p>{ chickenitem.chinese } </li>

               </div>
            </div>
             )
          }
    </div>
    </div>
    </section>
    
    <section id="hotpotitems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Hot Pot</h5>
      <hr />
            { content.hotpotitems.map((hotpotitem, index) =>
            <div className="row add-bottom">
              <div>

                        <li> { hotpotitem.name } - { hotpotitem.price } <p></p> { hotpotitem.chinese } </li>

               </div>
            </div>
             )
          }
    </div>
    </div>
    </section>

    <section id="riceandnoodleitems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Rice and Noodle</h5>
      <hr />
            { content.riceandnoodleitems.map((riceandnoodleitem, index) =>
            <div className="row add-bottom">
              <div>

                        <li> { riceandnoodleitem.name } - { riceandnoodleitem.price } <p></p> { riceandnoodleitem.chinese } </li>

               </div>
            </div>
             )
          }
    </div>
    </div>
    </section>
    
    <section id="chefspecialitems"
    style= {{
      padding: '2px 0 2px',
      background: '#FFBD9D'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Chef Special</h5>
      <hr />
            { content.chefspecialitems.map((chefspecialitem, index) =>
            <div className="row add-bottom">
              <div>

                        <li> { chefspecialitem.name } - { chefspecialitem.price }  </li>

               </div>
            </div>
             )
          }
    </div>
    </div>
    </section>

    <section id="specialsetitems"
    style= {{
      padding: '2px 0 2px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h5>Special Sets</h5>
      <hr />
            { content.specialsetitems.map((specialsetitem, index) =>
            <div className="row add-bottom">
              <div>

                        <li> { specialsetitem.name } - { specialsetitem.price } <p></p> { specialsetitem.chinese } </li>

               </div>
            </div>
             )
            }
    </div>
    <div className="six columns add-bottom">
      <h5>Dish Selections</h5>
      <hr />
            { content.dishselections.map((dishselectionsitem, index) =>
            <div className="row add-bottom">
              <div>
                        <li> { dishselectionsitem.name } </li>

               </div>
            </div>
             )
            }
    </div>
    </div>
    </section>
{/* 
    <section id="specialsetitems"
    style= {{
      padding: '90px 0 72px',
      background: '#F0F0F0'
    }}
    >
    <div className="row add-bottom">
    <div className="six columns add-bottom">
      <h2>Dish Selections (for Special Sets)</h2>
      <hr />
            { content.dishselections.map((dishselectionsitem, index) =>
            <div className="row add-bottom">
              <div>
                <h5> 
                    <ul>
                        <li> { dishselectionsitem.name } </li>
                     </ul>
                </h5>
               </div>
            </div>
             )
            }
    </div>
    </div>
    </section> */}

</Layout>
  </>
)